import Swiper from "swiper";

export function SwiperReviews() {
  const swiperContainers = document.querySelectorAll<HTMLElement>('[data-swiper="swiper-reviews"]');

  for(const swiperContainer of swiperContainers) {
    const swiper = swiperContainer?.querySelector<HTMLElement>('.swiper');
    if(swiper) {
      new Swiper(swiper, {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        navigation: {
          nextEl: swiperContainer.querySelector<HTMLElement>('button.next'),
          prevEl: swiperContainer.querySelector<HTMLElement>('button.prev'),
        },
        pagination: {
          el: swiperContainer.querySelector<HTMLElement>('div.pagination'),
          type: 'bullets',
          bulletActiveClass: '!bg-primary-800',
          bulletClass: 'bg-transparent',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
        },
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
      });
    }
  }
}
