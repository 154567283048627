type ShowProps = {
  type: 'success' | 'error';
  message: string;
};

export type ToastType = {
  show: (props: ShowProps) => void;
};

const successIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n' +
  '  <path d="M20 6L9 17L4 12" stroke="#267339" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
  '</svg>';

const errorIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">\n' +
  '  <path d="M7.40958 12.9933L11.706 8.70682C11.8941 8.51867 11.9998 8.26349 11.9998 7.99741C11.9998 7.73132 11.8941 7.47614 11.706 7.28799C11.5178 7.09984 11.2626 6.99414 10.9966 6.99414C10.7305 6.99414 10.4753 7.09984 10.2872 7.28799L6.00076 11.5844L1.71436 7.28799C1.52621 7.09984 1.27103 6.99414 1.00495 6.99414C0.738876 6.99414 0.483696 7.09984 0.29555 7.28799C0.107404 7.47614 0.00170517 7.73132 0.00170517 7.99741C0.00170517 8.26349 0.107404 8.51867 0.29555 8.70682L4.59194 12.9933L0.29555 17.2798C0.2019 17.3726 0.127569 17.4832 0.0768426 17.6049C0.0261165 17.7267 0 17.8573 0 17.9892C0 18.1211 0.0261165 18.2517 0.0768426 18.3734C0.127569 18.4952 0.2019 18.6057 0.29555 18.6986C0.388435 18.7922 0.498943 18.8666 0.6207 18.9173C0.742457 18.968 0.873053 18.9941 1.00495 18.9941C1.13686 18.9941 1.26745 18.968 1.38921 18.9173C1.51097 18.8666 1.62147 18.7922 1.71436 18.6986L6.00076 14.4021L10.2872 18.6986C10.38 18.7922 10.4906 18.8666 10.6123 18.9173C10.7341 18.968 10.8647 18.9941 10.9966 18.9941C11.1285 18.9941 11.2591 18.968 11.3808 18.9173C11.5026 18.8666 11.6131 18.7922 11.706 18.6986C11.7996 18.6057 11.874 18.4952 11.9247 18.3734C11.9754 18.2517 12.0015 18.1211 12.0015 17.9892C12.0015 17.8573 11.9754 17.7267 11.9247 17.6049C11.874 17.4832 11.7996 17.3726 11.706 17.2798L7.40958 12.9933Z" fill="#267339"/>\n' +
  '</svg>';

const parser = new DOMParser();
export function Toast() {
  function show(props: ShowProps) {
    const {type, message} = props;
    const divContainer = document.createElement("div");
    divContainer.classList.add('appToast');

    const icon = parser.parseFromString(type === 'success' ? successIcon : errorIcon, "image/svg+xml");

    const title = document.createElement('h3');
    title.innerText = type === 'success' ? 'Success!' : 'Oh no!';

    const messageWrapper = document.createElement('div');
    messageWrapper.innerHTML = message;

    divContainer.appendChild(icon.documentElement);
    divContainer.appendChild(title);
    divContainer.appendChild(messageWrapper);

    document.body.appendChild(divContainer);

    divContainer.addEventListener('click', () => divContainer.remove());

    setTimeout(() => {
      divContainer.classList.add('bottom-16');
    }, 500);

    setTimeout(() => {
      if(divContainer) divContainer.remove();
    }, 8000);
  }

  window.Toast = {show};
}
