export function Header() {
  const body = document.querySelector("body");

  let currentOpenedSubmenu: Element | null = null;
  const listOfSubmenus = document.querySelectorAll('[data-submenu]');

  if(listOfSubmenus) {
    for (let submenu of listOfSubmenus) {
      submenu.children.item(0)?.addEventListener('click', () => {
        if(currentOpenedSubmenu) {
          currentOpenedSubmenu.classList.remove('open');

          if(currentOpenedSubmenu === submenu) {
            currentOpenedSubmenu = null;
            return;
          }
        }

        if(!submenu.classList.contains('open')) {
          currentOpenedSubmenu = submenu;
          submenu.classList.add('open');
        }
      });
    }
  }

  const primaryMenu = document.getElementById('primary-menu');
  const hamburgerMenu = document.getElementById('hamburger-menu');
  if(hamburgerMenu && primaryMenu) {
    hamburgerMenu.addEventListener('click', () => {
      body?.classList.toggle('overflow-hidden');
      hamburgerMenu.classList.toggle('open');

      if(primaryMenu.classList.contains('-left-full')) {
        primaryMenu.classList.remove('-left-full');
        primaryMenu.classList.add('left-0');
      } else {
        primaryMenu.classList.add('-left-full');
        primaryMenu.classList.remove('left-0');
      }
    });
  }
}
