import { z } from 'zod'

type FormHandlerProps = {
    selector: string;
    onValidationSuccess: (form: HTMLFormElement, token: string) => void;
    onError?: () => void;
};

export function formHandler(props: FormHandlerProps) {
    const { selector, onValidationSuccess, onError } = props
    const forms = document.querySelectorAll<HTMLFormElement>(selector)

    for (const form of forms) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            let schema = null;
            let result = null;

            if(window.validationSchema)
            {
                const schemaShape:any = {};
                for(let field:string in window.validationSchema)
                {
                    if(window.validationSchema[field].type == 'string')
                    {
                        schemaShape[field] = z.string().min(1);
                    }
                    else if(window.validationSchema[field].type == 'email')
                    {
                        schemaShape[field] = z.string().email();
                    }
                }

                schema  = z.object(schemaShape);

                console.log(schemaShape);
            }

            const formData = new FormData(form)
            const recaptchaKey: string | null = form.getAttribute('data-recaptcha-key')

            const data: Record<string, string> = {}
            for(const [key, value] of formData.entries())
            {
                const input = form.querySelector(`[name="${key}"]`)
                const container = input?.parentElement?.tagName === 'DIV' ? input?.parentElement : input?.parentElement?.parentElement
                if (container) container.classList.remove('invalid')

                data[key] = value?.toString()
            }

            if(schema)
            {
                result = schema.safeParse(data);
            }

            if(result == null || result.success)
            {
                if(window.grecaptcha && recaptchaKey)
                {
                    window.grecaptcha.ready(function() {
                        window.grecaptcha.execute(recaptchaKey, { action: 'submit' }).then(function(token: string) {
                            onValidationSuccess(form, token)
                        })
                    })
                }
                else
                {
                    onValidationSuccess(form, '')
                }
            }
            else
            {
                const fieldErrors = result.error.formErrors.fieldErrors
                const fieldKeys = Object.keys(fieldErrors)

                for (const key of fieldKeys) {
                    const input = form.querySelector(`[name="${key}"]`)
                    const container = input?.parentElement?.tagName === 'DIV' ? input?.parentElement : input?.parentElement?.parentElement
                    if (container) container.classList.add('invalid')
                }

                onError?.()
            }
        })
    }
}
