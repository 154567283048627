import Swiper from "swiper";

export function SwiperClients() {
  const swiperClients = document.querySelectorAll<HTMLElement>('[data-swiper="swiper-clients"]');
  for(const swiper of swiperClients) {
    new Swiper(swiper, {
      direction: 'horizontal',
      loop: true,

      breakpoints: {
        300: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 20,
        },
      },
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    });
  }
}
