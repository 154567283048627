export function ExpandableFeatures() {
  const expandableFeatures = document.querySelectorAll<HTMLElement>('[data-expandableFeatures="true"]');

  for(const features of expandableFeatures) {
    const ul = features.querySelector<HTMLUListElement>('[data-expandableFeaturesList="true"]');
    const image = features.querySelector<HTMLImageElement>('[data-expandableFeaturesImage="true"]');

    if(ul && image) {
      const buttons = ul.querySelectorAll<HTMLElement>('[role="button"]');
      for(const button of buttons) {
        button.addEventListener('click', () => {
          ul.querySelector('.active')?.classList.remove('active');
          button.classList.add('active');

          const src = button.dataset.image;

          if(src) image.src = src;
        });
      }
    }
  }
}
