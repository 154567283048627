import Swiper from "swiper";

export function SwiperFeatures() {
  const sections = document.querySelectorAll<HTMLElement>('[data-features="true"]');
  for(const section of sections) {
    const swiperContainer = section.querySelector<HTMLElement>('[data-swiper="swiper-features"]');
    if(swiperContainer) {
      const swiper = new Swiper(swiperContainer, {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
      });

      const ul = section.querySelector('[data-ulid="plan-features"]');
      if(ul) {
        ul.querySelectorAll('button')?.forEach(button => {
          button.addEventListener('click', () => {
            ul.querySelector('button.active')?.classList.remove('active');

            button.classList.add('active');

            const li = button.parentElement;
            if(li) {
              const children = Array.from(ul.children);
              const index = children.indexOf(li);

              swiper.slideTo(index);
            }
          });
        });
      }
    }
  }
}
