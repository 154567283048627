export function FAQ() {
  const faqCategoriesWrapper = document.getElementById('faq-categories');
  const faqContentWrapper = document.getElementById('faq-content');

  if(faqCategoriesWrapper && faqContentWrapper) {
    const categories = faqCategoriesWrapper.querySelectorAll('button');
    if(categories) {
      for(const category of categories) {
        category.addEventListener('click', () => {
          faqCategoriesWrapper.querySelector('button.active')?.classList.remove('active');

          category.classList.add('active');

          const ul = category.parentNode?.parentNode;
          const li = category.parentNode;
          if(ul && li) {
            const children = Array.from(ul.children);
            const index = children.indexOf(li as Element);

            faqContentWrapper.querySelector('ul.flex')?.classList.replace('flex', 'hidden');
            faqContentWrapper.children[index]?.classList.replace('hidden', 'flex');
          }
        });
      }
    }

    const questions = faqContentWrapper.querySelectorAll('button');
    if(questions) {
      for(const question of questions) {
        question.addEventListener('click', () => {
          const currentQuestion = faqContentWrapper.querySelector('button.active');
          currentQuestion?.classList.remove('active');

          if(currentQuestion !== question) question.classList.add('active');
        });
      }
    }
  }
}
