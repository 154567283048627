export function ZendeskChat() {
  setTimeout(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.dataset.cmpAb = '2'; // allow script to be executed without iubenda's consent
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=6ada8a20-e218-4864-8e7d-0bac3aca3fd2';

    script.onload = () => console.log('Zendesk script loaded successfully');
    script.onerror = console.log;

    document.body.appendChild(script);

    const openChatButtons = document.querySelectorAll('[data-openzendesk]');
    for(const chatButton of openChatButtons) {
      chatButton.addEventListener('click', () => {
        if(window.zE) {
          window.zE('webWidget', 'open');
        }
      });
    }
  }, 5000);
}
