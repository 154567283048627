import Swiper from "swiper";

export function SwiperIndustries() {
  const swiperContainers = document.querySelectorAll<HTMLElement>('[data-swiper="swiper-industries"]');
  for(const swiperContainer of swiperContainers) {
    const swiper = swiperContainer?.querySelector<HTMLElement>('.swiper');
    const length = Number(swiperContainer.dataset.length);
    if(swiper) {
      new Swiper(swiper, {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 16,
        // centeredSlides: true,
        navigation: {
          nextEl: swiperContainer.querySelector<HTMLElement>('button.next'),
          prevEl: swiperContainer.querySelector<HTMLElement>('button.prev'),
        },
        pagination: {
          el: swiperContainer.querySelector<HTMLElement>('div.pagination'),
          type: 'bullets',
          bulletActiveClass: '!bg-primary-800',
          bulletClass: 'bg-transparent shrink-0',
        },
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          660: {
            slidesPerView: 2,
            loop: length > 3,
            autoplay: length > 2 ? {
              delay: 5000,
            } : false,
            // centeredSlides: length > 3,
          },
          1024: {
            slidesPerView: 3,
            loop: length > 3,
            autoplay: length > 3 ? {
              delay: 5000,
            } : false,
            // centeredSlides: length > 3,
          },
        },
      });
    }
  }
}
