export function Modals() {
  const body = document.querySelector("body");

  const youtubeModal = document.getElementById('youtube-modal');
  const youtubeIframe = youtubeModal?.querySelector('iframe');
  const closeYoutubeModal = document.getElementById('close-youtube-modal');

  const popupInfoModal = document.getElementById('popup-info-modal');
  const closePopupInfoModal = popupInfoModal?.querySelector('.close');
  const wrapperPopupInfoModal = popupInfoModal?.querySelector('.wrapper');

  const modals = document.querySelectorAll<HTMLElement>('[data-modal]');

  if(modals) {
    for(const modal of modals) {
      if(modal.dataset.modal === 'youtube') {
        if(youtubeModal) {
          modal.addEventListener('click', () => {
            const src = modal.dataset.src;
            if(youtubeIframe && src) {
              youtubeIframe.src = src;

              youtubeModal.classList.remove('invisible');
              youtubeModal.classList.add('opacity-100');

              body?.classList.add('overflow-hidden');
            }
          });
        }
      } else if(modal.dataset.modal === 'popup-info') {
        if(popupInfoModal) {
          modal.addEventListener('click', () => {
            const target = modal.dataset.target;
            const linkFeature = modal.dataset.linkfeature;

            const content = target ? document.getElementById(target)?.cloneNode(true) as HTMLElement : undefined;
            const contentWrapper = popupInfoModal.querySelector('.content-wrapper');

            if(linkFeature) popupInfoModal.querySelector('.link-feature')?.setAttribute('href', linkFeature);

            if(content && contentWrapper) {
              while(contentWrapper.firstChild) contentWrapper.removeChild(contentWrapper.firstChild);

              content.classList.remove('hidden');
              content.removeAttribute('id');
              contentWrapper.appendChild(content);
            }

            popupInfoModal.classList.remove('invisible');
            popupInfoModal.classList.add('opacity-100');

            wrapperPopupInfoModal?.classList.remove('top-full', 'lg:-right-full');
            wrapperPopupInfoModal?.classList.add('top-20', 'lg:right-0');

            body?.classList.add('overflow-hidden');
          });
        }
      }
    }
  }

  if(closeYoutubeModal) {
    closeYoutubeModal.addEventListener('click', () => {
      youtubeModal?.classList.remove('opacity-100');
      youtubeModal?.classList.add('invisible');

      body?.classList.remove('overflow-hidden');

      if(youtubeIframe) youtubeIframe.src = '';
    });
  }

  const onClosePopupInfoModal = () => {
    popupInfoModal?.classList.remove('opacity-100');
    popupInfoModal?.classList.add('invisible');

    wrapperPopupInfoModal?.classList.add('top-full', 'lg:-right-full');
    wrapperPopupInfoModal?.classList.remove('top-20', 'lg:right-0');

    body?.classList.remove('overflow-hidden');
  };

  popupInfoModal?.addEventListener('click', event => {
    if(event.currentTarget === event.target) onClosePopupInfoModal();
  });
  closePopupInfoModal?.addEventListener('click', onClosePopupInfoModal);
}
