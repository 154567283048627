import Swiper from "swiper";
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper/modules";

import {Header} from "./Header.ts";
import {Blog} from './Blog.ts';
import {SwiperClients} from "./SwiperClients.ts";
import {Modals} from "./Modals.ts";
import {SwiperReviews} from "./SwiperReviews.ts";
import {SwiperIndustries} from "./SwiperIndustries.ts";
import {ZendeskChat} from "./ZendeskChat.ts";
import {FAQ} from './FAQ.ts';
import {ExpandableFeatures} from "./ExpandableFeatures.ts";
import {SwiperShiftTimeAuditing} from "./SwiperShiftTimeAuditing.ts";
import {Toast} from "./Toast.ts";
import {FormSender} from "./FormSender.ts";
import {SwiperFeatures} from "./SwiperFeatures.ts";

Swiper.use([Autoplay, Pagination, Navigation, EffectFade]);

window.addEventListener('DOMContentLoaded', () => {
  Header();
  Blog();
  Modals();
  ZendeskChat();
  SwiperClients();
  SwiperReviews();
  SwiperIndustries();
  SwiperShiftTimeAuditing();
  SwiperFeatures();
  FAQ();
  ExpandableFeatures();
  Toast();
  FormSender();
});
