const setupLoadMore = (btn: HTMLElement) => {
    const list: Element | undefined = btn.previousElementSibling?.children[0]

    if (!list) {
        return
    }

    const lastPage: number | null = Number(btn.getAttribute('data-lastPage'))
    const baseUrl: string | null = btn.getAttribute('data-blogMore')
    const searchTerm: string | null = btn.getAttribute('data-search')

    let curPage: number = Number(btn.getAttribute('data-page'))
    let loading: boolean = false

    const makeUrl = (): string => {
        let url: string = baseUrl + '/' + String(curPage + 1)

        if (searchTerm != '') {
            url += '?search=' + searchTerm
        }

        return url
    }

    const checkEnd = (): void => {
        if (curPage >= lastPage) {
            if (btn.parentNode) {
                btn.parentNode.removeChild(btn)
            }
        }
    }

    checkEnd()

    btn.addEventListener('click', async (): Promise<void> => {
        if (curPage < lastPage && !loading) {
            loading = true
            const response: Response = await fetch(makeUrl())
            if (response.ok) {
                list.innerHTML += await response.text()
                curPage++
                loading = false

                checkEnd()
            }
        }
    })
}

export function Blog(): void {

    for (let i: number = 0; i < document.querySelectorAll<HTMLElement>('[data-blogMore]').length; i++) {
        setupLoadMore(document.querySelectorAll<HTMLElement>('[data-blogMore]')[i])
    }
}
