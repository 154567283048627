import { formHandler } from './form-handler.ts'

export function FormSender() {
    formHandler({
        selector: '[data-form="true"]',
        onValidationSuccess(form, token) {
            const action = form.action
            const formData = new FormData(form)
            formData.append('token', token);

            const handler:string|null = form.getAttribute('data-request-method');
            const successFunctionName:string|null = form.getAttribute('data-request-success');
            const successFunction:Function|null|undefined = window[successFunctionName];

            fetch(action, {
                body: formData,
                method:'POST',
                headers: {
                    'X-October-Request-Handler': handler,
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then(response => response.json()).then(data => {
                if(data.success)
                {
                    if(successFunction)
                    {
                        successFunction(form, data);
                    }
                    else
                    {
                        window.Toast.show({
                            type: 'success',
                            message: data.message
                        })
                    }
                }
                else
                {
                    window.Toast.show({
                        type: 'error',
                        message: data.message
                    })
                }
            }).catch((e) => {
                alert('err')
                console.log(e);
                window.Toast.show({
                    type: 'error',
                    message: JSON.stringify(e)
                })
            })
        }
    })
}
